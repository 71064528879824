(function() {
    if (!$('.slick-slider').length) {
        return;
    }

    // $('.slick-slider').slick()
    $('.slick-slider').slick({
        // autoplay: true,
        arrows: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: '<i class="fas fa-angle-right"></i>',
        prevArrow: '<i class="fas fa-angle-left"></i>',
    });

  })()